import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";
import useToolService from "../../hooks/useToolService";
import {useSelector} from "react-redux";
import {getLocalizedLanguage, getMobile} from "../user/settingsSlice";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const ThankYou = () => {
    const {getLanguageLinksForPages, prepareMeta} = useToolService();
    const isMobile = useSelector(getMobile)

    const language = useSelector(getLocalizedLanguage);
    const { t } = useTranslation();

    useEffect(() => {
        window.ym(99263248,'reachGoal','thank_you_page_visiting');
    },[])


    return   <div className="articleBlock">
        <Helmet
            link={getLanguageLinksForPages("/thank-you/")}
            meta={prepareMeta(t("ThankYou.part2"), t("ThankYou.part1")+" "+t("ThankYou.part2"), "article", "/thank-you/", "")}
            title={t("ThankYou.part2")}
        />

        <div className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className="buyCardInsideWide thankYouBlock">
                    <h1>{t("ThankYou.part1")}</h1>
                    <h2>{t("ThankYou.part2")}</h2>
                    <div>
                        <Link to={"/"+language+"/panel"} className="redButton modalButtons flexCenterCenter modalInput">{t("Account")}</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ThankYou;