import useToolService from "../../../common/hooks/useToolService";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    getLanguage,
    getLocalizedLanguage,
    getMobile,
    getSearchValue,
    setSearchValue
} from "../../../common/features/user/settingsSlice";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import React from "react";
const StartPracticing = ({data}) => {
    const isMobile = useSelector(getMobile)
    const { t } = useTranslation();
    const [items, setItems] = useState([])
    const language = useSelector(getLocalizedLanguage);

    const {getTranslatedValue} = useToolService();
    const navigate = useNavigate();
    useEffect(()=>{
         setItems(data.map(item=>({alias: item.alias, id: item.id, name: getTranslatedValue(item.titles), picture: item.picture})))
    },[data])


    const handleOnSelect = (item) => {
        window.ym(99263248,'reachGoal','search_line');
          navigate("/"+language+"/products/"+item.alias)
    }
//className="spanResult"
    const formatResult = (item) => {
        return (
            <div className="spanResult" ><img src={item.picture}  /> <div >{item.name}</div></div>
        )
    }

    const formatResultMobile = (item) => {
        return (
            <div className="spanResultMobile" ><div>{item.name}</div></div>
        )
    }


    let actualSaleCLasses = "actualSale leftSideBlock";
    let mainPageSearchBlockInside = "mainPageSearchBlockInside";
    let SearchBar = "SearchBar";

    if(isMobile){
        actualSaleCLasses = "actualSale";
        mainPageSearchBlockInside = "mainPageSearchBlockInside wrapperInsideMobile";
        SearchBar = ""
    }


    return <div className={actualSaleCLasses} style={{backgroundImage: "url(/img/actualSale.png)"}}>
        <div className="mainPageSearchBlock">
            <div className={mainPageSearchBlockInside}>
                <h3 className="mainPageSearchBlockInsideH3">{t("MainSearchBoxH3")}</h3>
                <p>{t("MainSearchBoxDescription")}</p>

                <div className={SearchBar}>
                    <ReactSearchAutocomplete
                        items={items}
                        onSelect={handleOnSelect}
                        //autoFocus

                        showItemsOnFocus
                        formatResult={isMobile ? formatResultMobile : formatResult}
                    />
                </div>
            </div>
        </div>
    </div>

}

export default StartPracticing;