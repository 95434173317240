import React from "react";
import {useTranslation} from "react-i18next";
import useToolService from "../../hooks/useToolService";
import {setBlackBackground, setVisibleLoginModal} from "../application/applicationSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAuth} from "../auth/authSlice";
import {useCreateRequestPriceMutation, useGetRequestPriceByProductIdQuery} from "../requestPrice/requestPriceSlice";

const TestPreparationRequest = ({alias, data}) => {
    const { t } = useTranslation();
    const {getTranslatedValue} = useToolService();
    const isAuth = useSelector(selectIsAuth);
    const [createRequest] = useCreateRequestPriceMutation();
    const dispatch = useDispatch()
    const {
        data: dataPriceRequest,
        isLoading: isLoadingPriceRequest,
        isSuccess: isSuccessPriceRequest,
        isError: isErrorPriceRequest,
        error: errorPriceRequest
    } = useGetRequestPriceByProductIdQuery(data.id);

    const openLoginModal = () => {
        dispatch(setBlackBackground(true))
        dispatch(setVisibleLoginModal(true));
    }

    const askConsultation = () => {

        if(data.id === 4) {
            window.ym(99263248,'reachGoal','essay_get_consultation');
        }
        createRequest({id: data.id, value: ""})
    }

    let button;
    if(isSuccessPriceRequest){
        let inProcess = false;
        if(dataPriceRequest !== null && dataPriceRequest.length>0){
            const filtered = dataPriceRequest.filter(item=>item.inProcess);
            if(filtered.length>0){
                inProcess = true;
            }

        }
        button = <button disabled={inProcess} onClick={()=>isAuth ? askConsultation() : openLoginModal()} className="redButton roundedButton">{inProcess ? t("WaitConsultation") :t("GetConsultation")}</button>
    }

    return <>
        <div className="warningBlock">
            <b>{t("GetConsultationRecommendation.part1")}</b><br/>
            {getTranslatedValue(data.warnMessage)}<br/>
            {button}
        </div>

    </>



}

export default TestPreparationRequest;