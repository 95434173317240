import {Link, useSearchParams} from "react-router-dom";
import {
    useCheckLanguageLevelTestMutation,
    useGetLanguageLevelTestQuery
} from "../../../../common/features/languageLevelTest/languageLevelTestSliceApi";
import {useTranslation} from "react-i18next";
import useToolService from "../../../../common/hooks/useToolService";
import React, {useEffect, useRef, useState} from "react";
import LanguageLevelTestWorkPlaceInside_Back from "./LanguageLevelTestWorkPlaceInside_Back";
import {useDispatch, useSelector} from "react-redux";
import {
    setCurrentQuestionId,
    setFirstQuestionId,
    setNumberOfQuestions
} from "../../../../common/features/commonTestData/testProgressLine";

import {useLanguage} from "../../../../admin/context/LanguageContext";

import {
    setSourceQuestions
} from "../../../../common/features/languageLevelTest/languageLevelTestSlice";

import {KeyboardDoubleArrowLeft} from "@mui/icons-material";
import {getLastUrl, getTestStatus, setTestStatus} from "../../../../common/features/application/applicationSlice";
import TestWorkPlace from "../../../../common/features/testWorkPlace/TestWorkPlace";
import Loader from "../../../../project/component/Loader";
import {getLocalizedLanguage, getMobile, setGlobalLoader} from "../../../../common/features/user/settingsSlice";

import {Helmet} from "react-helmet-async";
import {useGetSendInformationMutation} from "../../../../common/features/results/resultsSlice";

const LanguageLevelTestWorkPlace = () => {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get("orderId");
    const entityId = searchParams.get("entityId");
    const language = useSelector(getLocalizedLanguage);
    const isMobile = useSelector(getMobile)
    const status = useSelector(getTestStatus)
    const {getTranslatedValue} = useToolService();
    const lastUrl = useSelector(getLastUrl)
    const [checkLanguageLevel] = useCheckLanguageLevelTestMutation();
    const [sendInformation] = useGetSendInformationMutation()

    const { t } = useTranslation();
    const {data, isLoading, isSuccess, isError, error} = useGetLanguageLevelTestQuery({entityId: entityId, orderId: orderId})

    useEffect(()=>{
        if(isSuccess){
            const firstId = data.questions[0].id;
            dispatch(setNumberOfQuestions(data.questions.length))
            dispatch(setCurrentQuestionId(firstId))
            dispatch(setFirstQuestionId(firstId))
            dispatch(setSourceQuestions(data.questions.map(question=>question.id)));
        }
    },[data, isSuccess])


    let content;


    const callbackAfterResult = async (result) => {
              await sendInformation({id: 0, value: result}).unwrap();
            console.log(result);
    }

    useEffect(()=>{
        if(status === "await"){
            dispatch(setGlobalLoader(false))
        }
        if(status === "started" && isSuccess){
            dispatch(setGlobalLoader(false))
        }
        if(status === "loading"){
            dispatch(setGlobalLoader(true))
        }
    }, [status, isSuccess])

    if(status === "await" && isSuccess){
        const currentDescription = getTranslatedValue(data.descriptions);
        content = <div className={isMobile ? "wrapper2": "wrapper"}>
            <div className="buyCard">
                <div className="articleMainBlock bottomM">
                    <p className="linkToBackBlock"><Link className="linkToBack" to={"/"+language+"/panel/"}><KeyboardDoubleArrowLeft/>{t("Account")}</Link></p>
                    <div dangerouslySetInnerHTML={{__html: currentDescription}}/>
                    <div className="centralBlock"><button className="infoButton roundedButton bigButton" onClick={()=>{dispatch(setTestStatus("started"))}}>Start</button></div>
                </div>

            </div>

        </div>

    }
    if(status === "started" && isSuccess){
        const sortedQuestions = [...data.questions].sort((a,b) => a.position-b.position)

        content = <TestWorkPlace
            callbackAfterResult={callbackAfterResult}
            data={sortedQuestions}
            url={'/'+language+"/panel/results/languageLevel/detailed?entityId="}
            api={checkLanguageLevel}
            orderId={orderId}
            time={0}
            list={sortedQuestions}
            title={getTranslatedValue(data.titles)}
        />

    }




    return <div>
        <Helmet>
            <title>{isSuccess ? getTranslatedValue(data.titles) : "Many Tests"}</title>
            <meta name='description' content="Panel" />
        </Helmet>
                    {content}

    </div>
}

export default LanguageLevelTestWorkPlace;