import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setVisibleCookiesModal} from "../features/application/applicationSlice";
import {Link} from "react-router-dom";
import {getLocalizedLanguage} from "../features/user/settingsSlice";
import {useEffect} from "react";

const CookieModal = () => {
    const {t } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector(getLocalizedLanguage)

    useEffect(() => {
        // Проверяем, было ли уже принято уведомление о cookies
        const isCookieAccepted = localStorage.getItem('cookieAccepted');

        if (!isCookieAccepted) {
            dispatch(setVisibleCookiesModal(true));
        }else{
            dispatch(setVisibleCookiesModal(false));
        }
    }, []);


    const cookieAcceptHandler = () => {
        localStorage.setItem('cookieAccepted', 'true');
        dispatch(setVisibleCookiesModal(false))
    }

    return <div className="cookieModalClass">
        <div id="cookie-consent">
            <p>{t("cookieModalDescription")} <a
                href={language === "ru" ? "/cookie-notice-ru" : "/cookie-notice-en"} rel="noreferrer" target="_blank">{t("cookieModalLink")}.</a></p>
            <div className="accept-cookies-wrapper">
                <button onClick={cookieAcceptHandler} id="accept-cookies">{t("accept")}</button>
            </div>
        </div>
    </div>
}

export default CookieModal;