
export default class RootAddress{
 //static getURL = "http://localhost:8081/panda_in_tuxedo";
    static getURL = "https://many-tests.com/panda_in_tuxedo"
//   static getURL = "https://istudy.com.ru/many-tests"
 // static getURL = "http://istudy.local";
 //static getURL = "http://192.168.0.209:8081";
 //static getURL = "https://istudy.com.ru";
 //  static getURL = "http://testing.istudy.local";
 //  static getURL = "http://testing.istudy.com.ru";
 // static getURL = "https://istudy.com.ru/testing";
 //  static getURL = "http://istudy.local/rest/testing/";
}