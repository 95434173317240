import {useGetCheckByIdQuery, useUpdateCheckPaidMutation} from "./checksSlice";
import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLocalizedLanguage, getMobile, setGlobalLoader} from "../user/settingsSlice";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router";
import OfferCheckboxBlock from "../offerCheckbox/OfferCheckboxBlock";
import {getOfferAccepted} from "../offerCheckbox/offerCheckboxBlockSlice";
import CurrencySelect from "../currency/CurrencySelect";


const CheckDetailed = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");
    const isMobile = useSelector(getMobile);
    const { t } = useTranslation();
    const [payApi] = useUpdateCheckPaidMutation();
    const language = useSelector(getLocalizedLanguage)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const offerAccepted = useSelector(getOfferAccepted);
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetCheckByIdQuery(id);

    useEffect(() => {

        if(data.productId === 4){
            window.ym(99263248,'reachGoal','essay_open_check');
        }
    },[data.productId])

    const pay = async () => {
        const object = {checkId: data.id, productId: data.productId, userId: data.userId, value: data.value}
        try{
            dispatch(setGlobalLoader(true));
            const result = await payApi(object);
            dispatch(setGlobalLoader(false));
           // console.log(result)
            if(result?.data?.success){
                window.location.replace(result?.data?.url)
            }

        }catch (e){
            dispatch(setGlobalLoader(false));
            console.log(e);
        }
    }

    let content;
    let button;
    let offerCheckbox;
    let paidImg;
    if(isSuccess){
        content = <table className="paymentTable">
            <tbody>
                <tr><td>{t("User")}</td><td>{data.firstName+" "+data.lastName+" ("+data.email+")"}</td></tr>
                <tr><td>{t("Service")}</td><td>{data.description}</td></tr>
                <tr><td>{t("ToPay")}</td><td>{data.value}₽ <CurrencySelect price={data.value} callback={()=>{}}/></td></tr>
            </tbody>
        </table>
        button = !data.paid && <button disabled={!offerAccepted} onClick={()=>pay()} className="greenButton roundedButton">{t("Pay")}</button>
        offerCheckbox = !data.paid && <OfferCheckboxBlock/>
        paidImg = data.paid && <div className="paidStampBlock"><img src={"/img/paid-stamp.png"} /></div>
    }
    if(isError){
        navigate("/"+language+"/error");
    }

    /*
    <div className="checkMainBlock">
                    <div className="checkBlock">
                        <div className="checkBlockBackground">
                            df
                        </div>

                    </div>
                    <div className="checkBlockCurved">

                    </div>
                </div>
    * */

    return <div>
        <Helmet

                       title={t("CheckForPayment")}
        />
        <div className="wrapper buyCard">
            <div className={isMobile ? "wrapperInsideMobile" : "buyCardInside"} >
                <h1>{t("CheckForPayment")}</h1>
                {content}
                {paidImg}
                <div className="aloneButtonCenter">
                    {button}

                </div>
                <div className="acceptOfferCenter">
                {offerCheckbox}
                </div>
            </div>
        </div>
    </div>


}

export default CheckDetailed;