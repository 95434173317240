import React from "react";
import WhiteBackgroundForModal from "./WhiteBackgroundForModal";
import {useDispatch, useSelector} from "react-redux";
import {PhoneInput, usePhoneValidation} from 'react-international-phone';
//import 'react-international-phone/style.css';

import {

    getVisibleRegistrationModal,

    setVisibleRegistrationModal
} from "../features/application/applicationSlice";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useToolService from "../hooks/useToolService";
import InputEmail from "./UI/InputEmail";
import InputPassword from "./UI/InputPassword";
import {getLanguage, getLocalizedLanguage, getMobile} from "../features/user/settingsSlice";
import {useRegistrationMutation} from "../features/auth/authApiSlice";
import {CheckCircle} from "@mui/icons-material";
import {
    selectConfirmPassword,
    selectEmail, selectFirstName, selectIsConfirmPasswordValid,
    selectIsEmailValid, selectIsFirstnameValid, selectIsLastnameValid,
    selectIsPasswordValid,
    selectLastName,
    selectLastNameError,
    selectPassword, selectPhone, setConfirmPassword, setConfirmPasswordError,
    setEmailError,
    setFirstnameError, setIsConfirmPasswordValid,
    setIsEmailValid,
    setIsFirstnameValid,
    setIsLastnameValid, setIsPasswordValid,
    setLastnameError, setPassword, setPasswordError, setPhoneError
} from "../features/auth/authSlice";
import InputLastname from "./UI/InputLastname";
import InputFirstname from "./UI/InputFirstname";
import InputPhone from "./UI/InputPhone";
import InputConfirmPassword from "./UI/InputConfirmPassword";

const RegistrationModal = () => {
    const lastName = useSelector(selectLastName);
    const firstName = useSelector(selectFirstName);
    const isFirstnameValid = useSelector(selectIsFirstnameValid)
    const isLastNameValid = useSelector(selectIsLastnameValid)
    const isConfirmPasswordValid = useSelector(selectIsConfirmPasswordValid)
    const isMobile = useSelector(getMobile);
    const [statusLoader, setStatusLoader] = useState(false)
    const [statusSend, setStatusSend] = useState(false)
    const [agreePol, setAgreePol] = useState(false);

    const [mailLink, setMailLink] = useState("")

    const phone = useSelector(selectPhone)
    const phoneValidation = usePhoneValidation(phone);

    const email = useSelector(selectEmail);
    const isEmailValid = useSelector(selectIsEmailValid)

    const password = useSelector(selectPassword);
    const isPasswordValid = useSelector(selectIsPasswordValid)
    const confirmPassword = useSelector(selectConfirmPassword);

    const visibleRegistrationModal = useSelector(getVisibleRegistrationModal);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const language = useSelector(getLanguage)
    const languageLocalized = useSelector(getLocalizedLanguage)
    const {loginHandler, forgotPasswordHandler, getLinkFromEmail, checkEmptyLength} = useToolService()

    const [registrationApi] = useRegistrationMutation();

    const registration = () => {
        checkEmptyLength(email, setEmailError, setIsEmailValid);
        checkEmptyLength(password, setPasswordError, setIsPasswordValid);
        checkEmptyLength(confirmPassword, setConfirmPasswordError, setIsConfirmPasswordValid);
        checkEmptyLength(firstName, setFirstnameError, setIsFirstnameValid);
        checkEmptyLength(lastName, setLastnameError, setIsFirstnameValid);
        /*
         if(phone.length === 0){
             dispatch(setPhoneError(t("InvalidEmptyField")))
         }
        if(email.length === 0){
             dispatch(setEmailError(t("InvalidEmptyField")))
             dispatch(setIsEmailValid(false));
         }
         if(password.length === 0){
             dispatch(setPasswordError(t("InvalidEmptyField")))
             dispatch(setIsPasswordValid(false))
         }
         if(confirmPassword.length === 0){
             setConfirmPasswordError(t("InvalidEmptyField"))
             setIsConfirmPasswordValid(false)
         }
         if(firstName.length === 0){
             dispatch(setFirstnameError(t("InvalidEmptyField")))
             dispatch(setIsFirstnameValid(false))
         }
         if(lastName.length === 0){
             dispatch(setLastnameError(t("InvalidEmptyField")))
             dispatch(setIsLastnameValid(false))
         }

 */
        if(agreePol && isEmailValid && isPasswordValid && isConfirmPasswordValid && isFirstnameValid && isLastNameValid){

            setStatusLoader(true)

            const object = {firstName: firstName, lastName: lastName, language: language, password: password, email: email, phoneNumber: phone}

            registrationApi(object).unwrap().then(()=>{
                setMailLink(getLinkFromEmail(email))
                setStatusSend(true)
                setStatusLoader(false)

            }).catch(error=>{
                setStatusLoader(false)
                switch (error.status){
                    case 409:
                        dispatch(setEmailError(t("UserExist")))
                        dispatch(setIsEmailValid(false));
                    break;
                    case 400:
                        switch (error.data.message){
                            case "firstName":
                                dispatch(setFirstnameError(t("InvalidEmptyField")))
                                dispatch(setIsFirstnameValid(false))
                            break;
                            case "lastName":
                                dispatch(setLastnameError(t("InvalidEmptyField")))
                                dispatch(setIsLastnameValid(false))
                                break;
                            case "password":
                                dispatch(setPasswordError(t("")))
                                dispatch(setIsPasswordValid(true))
                                break;
                            case "email":
                                dispatch(setEmailError(t("InvalidEmailAddress")))
                                dispatch(setIsEmailValid(false))
                                break;
                        }
                        break;
                }

                console.log(error)
            })

            dispatch(setPassword(""))
            dispatch(setConfirmPassword(""))
        }

    }

    const handleCheckboxChange = (e) => {
        setAgreePol(e.target.checked);
    };

    let lineClass = "flexLine";
    if(isMobile){
        lineClass = "flexLineColumn"
    }

    return <div style={{display: visibleRegistrationModal ? "block" : "none"}}>
          <WhiteBackgroundForModal extraClassBlock={!isMobile && "wideWhiteBoardModal"} modalTitle={statusSend
            ? <CheckCircle className="successColor modalIcon"/>
            : t("Registration")
        }
                                 modalDescription={
                statusSend
                    ?
                    t("SuccessRegistration")
                    : ""}
                                 close={()=>dispatch(setVisibleRegistrationModal(false))}>

            <div className="formInputs">
                {!statusSend && <>
                    <div className={lineClass}><InputEmail classes={isMobile ? "modalInput" : "modalInputWide"}/>

                    </div>
                    <div className={lineClass}><InputFirstname classes="modalInput"/>
                        <InputLastname classes="modalInput"/></div>

                    <div className={lineClass}><InputPassword classes="modalInput"/>
                        <InputConfirmPassword classes="modalInput"/></div>

                    <InputPhone classes="modalInput"/><br/>
                    <label style={{marginBottom: "10px"}}>
                        <input type="checkbox"
                               style={{marginRight: "5px"}}
                               checked={agreePol}
                               onChange={handleCheckboxChange}/>
                        {t("acceptPrivacy.part1")}<br/> {t("acceptPrivacy.part2")}
                        <a style={{marginLeft: "5px"}} href={language === "ru" ? "/privacy-policy-ru" : "/privacy-policy-en"} target="_blank" rel="noopener noreferrer">
                            {t("acceptPrivacy.part3")}
                        </a>
                    </label>
                </>
                }
                {
                    mailLink.length === 0
                        ? <button
                            disabled={(!(agreePol && isEmailValid && isPasswordValid && isConfirmPasswordValid && isFirstnameValid && isLastNameValid)) || statusLoader}
                            type="button" onClick={() => registration()}
                            className="redButton modalButtons flexCenterCenter">{t('Send')}<img
                            style={{display: statusLoader ? "block" : "none"}} className="smallSpinLoader"
                            src="/img/spinLoader.gif" alt="Loading..."/></button>
                        : <a href={mailLink} target={"_blank"}
                             className="redButton modalButtons flexCenterCenter buttonLink">{t('goToMail')}
                        </a>
                }
                {!statusSend &&
                    <div className="loginModalBottomLinksBlock">
                        <div className="registrationLinkModal" onClick={() => loginHandler()}>{t("Login")}</div>
                        <div className="registrationLinkModal"
                             onClick={() => forgotPasswordHandler()}>{t("ForgotPassword")}</div>
                    </div>
                }
                </div>

                    </WhiteBackgroundForModal>
    </div>
}

export default RegistrationModal;