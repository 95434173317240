import {Link} from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage, getLocalizedLanguage, setLanguage} from "../../common/features/user/settingsSlice";

import {selectIsAuth} from "../../common/features/auth/authSlice";
import {setBlackBackground, setVisibleLoginModal} from "../../common/features/application/applicationSlice";

import {useTranslation} from "react-i18next";

import {useUpdateLanguageMutation} from "../../common/features/user/userApiSlice";

const TopbarMobile = () => {
    const {t, i18n } = useTranslation();
    const isAuth = useSelector(selectIsAuth);
    const language = useSelector(getLanguage)
    const languageLocalization = useSelector(getLocalizedLanguage)
    const dispatch = useDispatch();
    const [updateLanguage] = useUpdateLanguageMutation()

    const changeLanguageHandler = (code) => {
        window.ym(99263248,'reachGoal','select_language');
        if(isAuth){
            updateLanguage(code)
        }
        dispatch(setLanguage(code))
        i18n.changeLanguage(code)
    }

    const openLoginModal = () => {
        dispatch(setBlackBackground(true))
        dispatch(setVisibleLoginModal(true));
    }
 /*
    useEffect(()=>{
        if(i18n.language !== language){
            dispatch(setLanguage(i18n.language))
        }
    },[i18n.language])
*/
    return <div className="topBarMainBlock" ><div className="topbarDesktop wrapperInsideMobile">
        <div>
            <div className="logoBlockMobile">
                <Link to={"/"+languageLocalization+"/"}><img className="logoMobile" src="/img/logo2.webp" />
                </Link>
            </div>

        </div>


            <div><ReactFlagsSelect
                selected={language}
                //optionsSize={10}
                rfsKey="app-lang-mobile"
                countries={["US", "ES", "RU", "CN", "DE", 'KZ', 'FR']}
                customLabels={{"US": "English","ES": "Español", "RU": "Русский", "CN":"中国人", "DE":"Deutsch", "KZ":"Қазақ тілі", "FR":"Français"}}
                placeholder="Select Language"
                onSelect={(code) => changeLanguageHandler(code)}
            /></div>

    </div>


    </div>
}

export default TopbarMobile;