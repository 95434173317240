import {useDispatch, useSelector} from "react-redux";
import {
    getCurrentQuestionId,
    getNumberOfQuestions,
     setCurrentQuestionId
} from "../commonTestData/testProgressLine";
import {getAnswers} from "../commonTestData/testAnswersSlice";
import {getMobile} from "../user/settingsSlice";
import {setTestStatus, setVisibleWarningCancelModal} from "../application/applicationSlice";
import React from "react";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";

import {getFullSeconds, getTotalTime} from "../commonTestData/timerSlice";
import {getSourceQuestions} from "../languageLevelTest/languageLevelTestSlice";

const BottomQuestionNavigation = ({nextQuestion, previousQuestion, url, api, callbackAfterResult}) => {
    const dispatch = useDispatch()
    const numberOfQuestions = useSelector(getNumberOfQuestions)
    const currentQuestion = useSelector(getCurrentQuestionId)
    const navigate = useNavigate();
    const answers = useSelector(getAnswers)
    const isMobile = useSelector(getMobile)

    const [searchParams] = useSearchParams()
    const orderId = searchParams.get("orderId");
    const spentTime = useSelector(getFullSeconds)
    const totalTime = useSelector(getTotalTime)
    const source = useSelector(getSourceQuestions)

    const save = async () => {
            try {
               dispatch(setTestStatus("loading"))
               const realTime = totalTime > 0 ? (totalTime - spentTime) : spentTime;

               const result = await api({body:{spentTime: realTime, totalTime, answers, source}, orderId: orderId}).unwrap();

                if(callbackAfterResult){

                    callbackAfterResult(result)
                }


               dispatch(setTestStatus("await"))
               navigate(url+result)

            } catch (err) {
               console.log(err)
            }
    }


    let content = <div className="bottomInformationLine">
        <hr/><div className="bottomInformationLineCenter">
        <span>Answers {answers.length}/{numberOfQuestions}</span>
        <div className="nextQuestionButtonBlock">
            {previousQuestion && <button className="greyButton roundedButton bigButton" onClick={()=>dispatch(setCurrentQuestionId(previousQuestion.id))}>Previous</button>}
            {nextQuestion && <button className="infoButton roundedButton bigButton" onClick={()=>dispatch(setCurrentQuestionId(nextQuestion.id))}>Next</button>}
            {!nextQuestion && <button className="greenButton roundedButton bigButton" onClick={()=>save()}>Save</button>}
        </div>
        <div>
            <button className="redButton roundedButton bigButton" onClick={()=>{dispatch(setVisibleWarningCancelModal(true))}}>Cancel</button>
        </div>
    </div></div>

    if(isMobile){
        content =<div className="bottomInformationLine">
            <hr/> <div className="bottomInformationLineCenter bottomInformationLineCenterMobile">
            <div className="nextQuestionButtonBlock">
                {previousQuestion && <button className="greyButton roundedButton bigButton" onClick={()=>dispatch(setCurrentQuestionId(previousQuestion.id))}>Previous</button>}
                {nextQuestion && <button className="infoButton roundedButton bigButton" onClick={()=>dispatch(setCurrentQuestionId(nextQuestion.id))}>Next</button>}
                {!nextQuestion && <button className="greenButton roundedButton bigButton" onClick={()=>save()}>Save</button>}
            </div>

            <span className="mobileAnswers">Answers {answers.length}/{numberOfQuestions}</span>
            <div><br/>
                <button className="redButton roundedButton bigButton" onClick={()=>{dispatch(setVisibleWarningCancelModal(true))}}>Cancel</button>
            </div>
        </div></div>
    }

    return content



}

export default BottomQuestionNavigation;