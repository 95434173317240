import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    blackBackground: false,
    visibleLoginModal: false,
    visibleRegistrationModal: false,
    visibleForgotPasswordModal: false,
    visibleHorizontalLoaderModal: false,
    visibleNewPasswordModal: false,
    visibleSuccessModal: false,
    visibleConsultationModal: false,
    visibleBottomMobileBar: true,
    visibleWarningCancelModal: false,
    visibleCookiesModal: true,
    visibleErrorModal: false,
    consultationUrl: "",
    scrollPosition: 0,
    testStatus: "await",
    lastUrl: "/"
}

const applicationSlice = createSlice({
        name: '@@application',
        initialState: initialState,
        reducers: {
            setTestStatus:(state, action)=>{
                state.testStatus = action.payload;
            },
            setVisibleCookiesModal:(state, action)=>{
                state.visibleCookiesModal = action.payload;
            },
            setLastUrl:(state, action)=>{
                state.lastUrl = action.payload;
            },
            setConsultationUrl:(state, action)=>{
                state.consultationUrl = action.payload;
            },
            setBlackBackground:(state, action)=>{
                state.blackBackground = action.payload;
            },
            setVisibleSuccessModal:(state, action)=>{
                state.visibleSuccessModal = action.payload;
            },
            setVisibleErrorModal:(state, action)=>{
                state.visibleErrorModal = action.payload;
            },
            setVisibleWarningCancelModal:(state, action)=>{
                state.blackBackground = action.payload;
                state.visibleWarningCancelModal = action.payload;
            },
            setVisibleNewPasswordModal:(state, action)=>{
                state.visibleNewPasswordModal = action.payload;
            },
            setVisibleHorizontalLoaderModal:(state, action)=>{
                state.visibleHorizontalLoaderModal = action.payload;
            },
            setVisibleLoginModal:(state, action)=>{
                state.visibleLoginModal = action.payload;
            },
            setForgotPasswordModal:(state, action)=>{
                state.visibleForgotPasswordModal = action.payload;
            },
            setBottomMobileBar:(state, action)=>{
                state.visibleBottomMobileBar = action.payload;
            },
            setVisibleRegistrationModal:(state, action)=>{
                state.visibleRegistrationModal = action.payload;
            },
            setScrollPosition:(state, action)=>{
                state.scrollPosition = action.payload;
            },
            setVisibleConsultationModal:(state, action)=>{
                state.visibleConsultationModal = action.payload;
            },
        },
    }
)

export const {setVisibleCookiesModal, setVisibleErrorModal, setLastUrl, setVisibleWarningCancelModal, setTestStatus, setBottomMobileBar, setConsultationUrl, setVisibleConsultationModal, setScrollPosition, setVisibleNewPasswordModal,setVisibleHorizontalLoaderModal, setVisibleSuccessModal, setForgotPasswordModal, setBlackBackground, setVisibleLoginModal, setVisibleRegistrationModal} = applicationSlice.actions;
export const applicationReducer = applicationSlice.reducer;
export const getBlackBackground = (state) => state.application.blackBackground
export const getLastUrl = (state) => state.application.lastUrl
export const getTestStatus = (state) => state.application.testStatus
export const getScrollPosition = (state) => state.application.scrollPosition
export const getVisibleLoginModal = (state) => state.application.visibleLoginModal
export const getVisibleCookiesModal = (state) => state.application.visibleCookiesModal
export const getVisibleWarningCancelModal = (state) => state.application.visibleWarningCancelModal
export const getVisibleNewPasswordModal = (state) => state.application.visibleNewPasswordModal
export const getVisibleHorizontalLoaderModal = (state) => state.application.visibleHorizontalLoaderModal
export const getVisibleRegistrationModal = (state) => state.application.visibleRegistrationModal
export const getVisibleSuccessModal = (state) => state.application.visibleSuccessModal
export const getVisibleErrorModal = (state) => state.application.visibleErrorModal
export const getVisibleConsultationModal = (state) => state.application.visibleConsultationModal
export const getVisibleForgotPasswordModal = (state) => state.application.visibleForgotPasswordModal
export const getVisibleBottomMobileBar = (state) => state.application.visibleBottomMobileBar
export const getConsultationUrl = (state) => state.application.consultationUrl